/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { linear } from 'regression';

export const transformFunctions = {
  delta: function (values: number[]) {
    const result = [];
    for (let i = 1; i < values.length; i++) {
      const x = values[i] - values[i - 1];
      if (x > -1) result.push(x);
      else result.push(0);
    }
    return result;
  }
};

export const displayFunctions = {
  sum: function (values: number[]) {
    let s = 0;
    for (let i = 0; i < values.length; i++) {
      s = s + (values[i] ? parseInt(values[i] as any) : 0);
    }
    return s;
  },
  max: function (values: number[]): number {
    return values[values.length - 1] ? values[values.length - 1] : 0;
  },
  lastValue: function (values: number[]): number {
    return values[values.length - 1] ? values[values.length - 1] : 0;
  },
  undefined: function (values: number[]): number {
    return values[values.length - 1] ? values[values.length - 1] : 0;
  }
};

export function getTrend(values: number[]): number {
  const r: Array<[number, number]> = values.map(
    (item: number, index: number) => [index, item]
  );
  const result = linear(r);
  const res = result.predict(r.length - 1)[1] - result.predict(0)[1];
  if (res > 0) return 1;
  if (res === 0) return 0;
  if (isNaN(res)) return 0;
  return -1;
}
