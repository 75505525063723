/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const getCacheKey = (name: string, url: string): string => {
  return `${url
    .slice(url.split('/', 3).join('/').length + 1, url.lastIndexOf('/'))
    .replaceAll('/', '_')}_${name}`;
};

export const evaluateLocks = (lockers: any): boolean => {
  if (lockers.length === 0) {
    return false;
  }
  const waitingTime = 6000;
  const timestemp = new Date().getTime();
  const newLockers = lockers.filter((item: any) => {
    if (timestemp - item.time > waitingTime) {
      return false;
    }
    return true;
  });
  // return true if newLockers has at least one item true
  return newLockers.filter((item: any) => item).length > 0;
};
