/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resolve } from '../../../api/endPoints';
import { getCacheKey } from '../utils/utils';
import axiosClient from '../../../utils/axios';
import {
  readLocationCache,
  updateLocationCache,
  writeLocationCache
} from '../cache/locationCache';

const CACHE_KEY = getCacheKey('user_info', window.location.href);
const FIXED_LOCATION_NAME = 'uuli';

interface UserDetails {
  userData: any;
  name: string;
  firstName: string;
  lastName: string;
  initials: string;
  uuid: string;
  isClientPortalUser: boolean;
  isLomavisConnectUser: boolean;
  displayMode: 0;
  isLoading: boolean;
  firebaseAuthToken: string | null;
  waffle_flags: {
    react_firebase_dashboard_analytics: boolean;
    react_firebase_facebook_analytics: boolean;
    react_firebase_instagram_analytics: boolean;
    react_firebase_google_analytics: boolean;
    react_firebase_twitter_analytics: boolean;
    react_firebase_linkedin_personal_account_analytics: boolean;
    react_firebase_linkedin_business_page_analytics: boolean;
  };
  hasSocialMediaManagementSubscription: boolean;
  hasCloudSubscription: boolean;
  locations: any[];
  currentPageLocations: any[];
  rowsNumber: number;
  next: string | null;
  count: number;
  error: boolean | string;
}

const initialState: UserDetails = {
  userData: window?.lomavisUserData?.userData,
  name: '',
  firstName: '',
  lastName: '',
  initials: '',
  uuid: '',
  isClientPortalUser: false,
  isLomavisConnectUser: false,
  displayMode: 0,
  isLoading: false,
  firebaseAuthToken: null,
  waffle_flags: {
    react_firebase_dashboard_analytics: false,
    react_firebase_facebook_analytics: false,
    react_firebase_instagram_analytics: false,
    react_firebase_google_analytics: false,
    react_firebase_twitter_analytics: false,
    react_firebase_linkedin_personal_account_analytics: false,
    react_firebase_linkedin_business_page_analytics: false
  },
  hasSocialMediaManagementSubscription: false,
  hasCloudSubscription: false,
  locations: [],
  currentPageLocations: [],
  rowsNumber: 5,
  count: 0,
  next: null,
  error: false
};

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.uuid = action.payload.data.uuid;
      state.name = action.payload.data.name;
      state.firstName = action.payload.data.first_name;
      state.lastName = action.payload.data.last_name;
      state.name = action.payload.data.display_name;
      state.initials = action.payload.data.initials;
      state.isClientPortalUser = action.payload.data.is_client_portal_user;
      state.isLomavisConnectUser = action.payload.data.is_lomavis_connect_user;
      state.displayMode = action.payload.data.setting_media_library_view_mode;
      state.hasSocialMediaManagementSubscription =
        action.payload.data.has_social_media_management_subscription;
      state.hasCloudSubscription = action.payload.data.has_cloud_subscription;
      state.firebaseAuthToken = action.payload.data.firebase_auth_token;
      state.waffle_flags = action.payload.data?.waffle_flags;
    },
    updateDisplayModeValue: (state, action: PayloadAction<any>) => {
      state.displayMode = action.payload.setting_media_library_view_mode;
    },
    hasError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    }
  }
});

export default slice.reducer;

export function getUserDetails() {
  return async (dispatch: any) => {
    const cachedData: any = readLocationCache(FIXED_LOCATION_NAME, CACHE_KEY);
    if (cachedData) {
      dispatch(slice.actions.setUserDetails({ data: cachedData }));
      dispatch(slice.actions.stopLoading());
    }
    const url = resolve('user_details', {}, {});
    try {
      const response = await axiosClient.get(url);
      dispatch(
        slice.actions.setUserDetails({ data: response.data.results[0] })
      );
      writeLocationCache(
        FIXED_LOCATION_NAME,
        CACHE_KEY,
        response.data.results[0]
      );
    } catch (err) {
      console.log(err);
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function updateDisplayMode(display: number) {
  return async (dispatch: any) => {
    const url = resolve('user_details', {}, {});
    try {
      await axiosClient.patch(url + 'af89d6b3-b69f-4157-bda2-54d34730e6c7/', {
        setting_media_library_view_mode: display
      });
      dispatch(
        slice.actions.updateDisplayModeValue({
          setting_media_library_view_mode: display
        })
      );
      updateLocationCache(FIXED_LOCATION_NAME, CACHE_KEY, {
        setting_media_library_view_mode: display
      });
    } catch (err) {
      console.log(err);
    }
  };
}

export async function getFirebaseToken(): Promise<any> {
  const url = resolve('user_details', {}, {});
  return await new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(url);
      resolve(response.data.results[0].firebase_auth_token);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}
