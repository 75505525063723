/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
export function getNumBucketsInRange(
  startDate: Date,
  endDate: Date,
  bucketSize: number
): number {
  if (endDate < startDate) {
    throw new Error('End date must be after start date');
  }
  const epochStart = new Date(0);
  const startSecondsSinceEpoch =
    (startDate.getTime() - epochStart.getTime()) / 1000;
  const endSecondsSinceEpoch =
    (endDate.getTime() - epochStart.getTime()) / 1000;
  const startBucket = Math.floor(
    startSecondsSinceEpoch / (bucketSize * 24 * 60 * 60)
  );
  const endBucket = Math.floor(
    endSecondsSinceEpoch / (bucketSize * 24 * 60 * 60)
  );
  const numBuckets = endBucket - startBucket + 1;
  return numBuckets;
}

export function getAllDates(startDate: any, endDate: any): any {
  const allDates = [];

  // Copy the start date to a new date object
  const currentDate: any = new Date(startDate);

  // Loop until the current date is equal to or greater than the end date
  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    allDates.push(new Date(currentDate).toISOString()); // Add the current date to the list
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    currentDate.setDate(currentDate.getDate() + 1); // Increment the current date by one day
  }

  return allDates;
}

export function formatDate(date: any): string {
  const _date = new Date(date);
  const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return _date.toLocaleDateString(lang, options as any);
}
