import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosClient from '../../../utils/axios';
import { resolve } from '../../../api/endPoints';

interface SubscriptionData {
  max_team_members: number | null;
  team_member_count: number;
  available: number | null;
}

interface SubscriptionState {
  isLoading: boolean;
  data: SubscriptionData | null;
  error: string | null;
}

const initialState: SubscriptionState = {
  isLoading: false,
  data: null,
  error: null
};

const subscriptionManagementSlice = createSlice({
  name: 'subscriptionManagement',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setData: (state, action: PayloadAction<SubscriptionData>) => {
      state.data = action.payload;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setTeamMemberCount: (state, action: PayloadAction<number>) => {
      if (state.data !== undefined && state.data !== null) {
        if (
          state.data.team_member_count !== undefined &&
          state.data.team_member_count !== null
        )
          state.data.team_member_count =
            state.data.team_member_count + action.payload;
        if (state.data.available !== undefined && state.data.available !== null)
          state.data.available = state.data.max_team_members - action.payload;
      }
    }
  }
});

export default subscriptionManagementSlice.reducer;

export const fetchSubscriptionData = () => async (dispatch: any) => {
  dispatch(subscriptionManagementSlice.actions.startLoading());
  try {
    const response = await axiosClient.get(
      resolve('subscription_management_status', {}, {})
    );
    const data = response.data;
    dispatch(subscriptionManagementSlice.actions.setData(data));
  } catch (error) {
    console.error(error);
    dispatch(
      subscriptionManagementSlice.actions.setError(
        'Failed to fetch subscription data'
      )
    );
  } finally {
    dispatch(subscriptionManagementSlice.actions.stopLoading());
  }
};

export const updateTeamMembersCount =
  (number: number) => async (dispatch: any) => {
    dispatch(subscriptionManagementSlice.actions.setTeamMemberCount(number));
  };
